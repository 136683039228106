import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.title)
    ? _withDirectives((_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(['planning-badge', _ctx.classModifier])
      }, [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ], 2)), [
        [_directive_tooltip, _ctx.tooltip]
      ])
    : _createCommentVNode("", true)
}