import { watch } from 'vue';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import PageBaseModule from '@/store/page';
import UserModule from '@/store/user';
import { Filter } from '@/lib/layouts/page/filter.interface';
import filterModel from '@/store/tasks/outputs/filter';
import inlineFilterModel from '@/store/tasks/outputs/inlineFilter';
import OutputsFilter from '@/store/tasks/outputs/filterEntity';
import OutputsInlineFilter from '@/store/tasks/outputs/inlineFilterEntity';
import { Checkbox } from '@/interfaces/filter.interface';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';
import { getClients } from '@/api/client';
import { getAllPartnersList } from '@/api/partners';
import { getOutputsList, updateViewed } from '@/api/outputs';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { prepareList } from '@/lib/Outputs';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { strings } from '@/lib/stringConst';
import { convertObjectToQueryParams } from '@/lib/Utils';
import { getServiceTypeAll } from '@/api/templates';
import { ServiceTypeMin } from '@/interfaces/models/serviceType.interface';

export const MODULE_NAME = 'outputs';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class OutputsModule extends PageBaseModule {
  shift: {} = {};
  filter: Filter;
  inlineFilter: Filter;
  enumDays: Record<string, string> = {
    '-1': 'Вчера',
    '0': 'Сегодня',
    '1': 'Завтра',
    '2': 'Послезавтра',
  };
  defaultCheckboxFilter =
    '&filters[14][id]=confirmed&filters[14][value]=1' +
    '&filters[15][id]=did_not_confirm&filters[15][value]=1' +
    '&filters[16][id]=no_need_confirm&filters[16][value]=1' +
    '&filters[17][id]=refused&filters[17][value]=1';

  allServiceTypes: ServiceTypeMin[] = [];

  get inlineFilterModel() {
    return [
      {
        id: 'date',
        value: (this.inlineFilter.filterSettings.filterModel.date.current as Record<string, string>)?.date ?? '',
      },
      {
        id: 'viewed',
        value: (this.inlineFilter.filterSettings.filterModel.viewed?.list as Checkbox[])?.[0]?.checked ? '1' : '0',
      },
    ];
  }

  constructor(module: OutputsModule) {
    super(module);

    const page = new PageEntity();
    this.pageSettings = page.values;
    page.values.actionPagination = 'outputs/updatePage';

    const filter = new filterModel();
    this.filter = getModule(OutputsFilter);
    this.filter.setFilterName('outputsFilter');
    this.filter.setTemplateClassName('template-lg-with-sub');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
    this.filter.setBtnClassName('col-1-row-7-full');
    this.filter.setDefaultIndex(4);

    const inlineFilter = new inlineFilterModel();
    this.inlineFilter = getModule(OutputsInlineFilter);
    this.inlineFilter.setFilterName('outputsInlineFilter');
    this.inlineFilter.setFilterModel(inlineFilter.filterModel);
    this.inlineFilter.setFilterHandlers(inlineFilter.filterHandlers);
    this.inlineFilter.setDefaultIndex(0);

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        if (!isSupervisor) {
          this.filter.resetSelect('partner');
        }
      },
      { immediate: true }
    );
  }

  @Mutation
  SET_SHIFT(shift: {}) {
    this.shift = shift;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.outputsSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_ALL_SERVICE_TYPES(serviceTypes: ServiceTypeMin[]): void {
    this.allServiceTypes = serviceTypes;
  }

  @Action({ rawError: true })
  async updateIsProcessedFilter(params: { key: string; checkbox: { nool: boolean; id: string; name: string } }) {
    await this.filter.updateCheckbox(params);
    this.filter.updateFilter();
  }

  @Action({ rawError: true })
  async initClientsSelect() {
    const result: { clients: { id: string; name: string }[] } = await getClients();

    return Object.values(result.clients).map((client: { id: string; name: string }) => {
      return {
        id: client.id,
        value: client.id,
        name: client.name,
        checked: false,
      };
    });
  }

  @Action({ rawError: true })
  async initServiceTypeSelect() {
    const serviceTypes = await this.getAllServiceTypes();

    return await this.prepareServiceTypeItems(serviceTypes);
  }

  @Action({ rawError: true })
  async initPartnersSelect() {
    try {
      if (!UserModule.isSupervisor) {
        return [];
      }

      const partnersAll: PartnerSelectListItem[] = await getAllPartnersList();

      return Object.values(partnersAll).map((partner: PartnerSelectListItem) => {
        return {
          id: partner.uuid,
          value: partner.legalName,
          name: partner.legalName,
        };
      });
    } catch (e) {
      return [];
    }
  }

  @Action({ rawError: true })
  async init() {
    this.context.commit('SET_SORT', window.localStorage.outputsSort ? JSON.parse(window.localStorage.outputsSort) : {});
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.filter.init();
    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const sort = await this.getSortForRequest();
      const inlineFilters = convertObjectToQueryParams({ filters: this.inlineFilterModel });

      let filter = `&${inlineFilters}${this.filter.filterSettings.filter}`;

      const valid = await this.validateFilter(
        this.filter.filterSettings.filterModel as Record<string, { list: { checked: boolean }[] }>
      );

      if (!valid) {
        filter += this.defaultCheckboxFilter;
      }

      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getOutputsList(this.pageSettings.pageCurrent, sort, filter, itemsQuery);
      await this.context.dispatch('setList', result.table);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  validateFilter(model: Record<string, { list: { checked: boolean }[] }>): boolean {
    return !!(
      model.confirmed.list[0].checked ||
      model.did_not_confirm.list[0].checked ||
      model.no_need_confirm.list[0].checked ||
      model.refused.list[0].checked ||
      model.status.list[0].checked
    );
  }

  @Action({ rawError: true })
  async updatePage(number: string) {
    await this.context.commit('SET_PAGE', parseInt(number));
    await this.getList();
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    this.context.commit('SET_TABLE', prepareList(table, this));
  }

  @Action({ rawError: true })
  async getAllServiceTypes() {
    if (!this.allServiceTypes.length) {
      this.SET_ALL_SERVICE_TYPES(await getServiceTypeAll());
    }

    return this.allServiceTypes;
  }

  @Action({ rawError: true })
  prepareServiceTypeItems(serviceTypes: ServiceTypeMin[]) {
    const filterModelList = (this.filter.filterSettings.filterModel.serviceType?.list ?? []) as Checkbox[];

    return serviceTypes.map((serviceType) => {
      const filterModelItem = filterModelList.find((item) => item.id.toString() === serviceType.id.toString());
      const checked = filterModelItem?.checked ?? false;

      return {
        id: serviceType.id,
        value: serviceType.id,
        name: serviceType.name,
        checked,
      };
    });
  }

  @Action({ rawError: true })
  resetClient(key: string) {
    this.filter.resetSelect(key);
    this.filter.resetMultiSelect('shiftTemplate');
    this.filter.updateSelectList({ key: 'shiftTemplate', list: [] });
  }

  @Action({ rawError: true })
  async updateViewed(params: { id: string; bool: boolean }) {
    try {
      const data = {
        viewed: params.bool ? 1 : 0,
      };
      const result = await updateViewed(params.id, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setShift(shift: {}) {
    this.context.commit('SET_SHIFT', shift);
  }
}

export default getModule(OutputsModule);
