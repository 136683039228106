import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-flex cp-flex--justify-between align-items-center cp-margin__b--small" }
const _hoisted_3 = { class: "cp-flex cp-flex--justify-between align-items-center" }
const _hoisted_4 = { class: "fullName" }
const _hoisted_5 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_6 = {
  key: 0,
  class: "cp-text-w500"
}
const _hoisted_7 = { class: "cp-flex align-items-center" }
const _hoisted_8 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_9 = { class: "cp-flex align-items-center" }
const _hoisted_10 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_title_return = _resolveComponent("title-return")!
  const _component_form_select = _resolveComponent("form-select")!
  const _component_app_header_main = _resolveComponent("app-header-main")!
  const _component_form_simple_switch = _resolveComponent("form-simple-switch")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_planning_badge = _resolveComponent("planning-badge")!
  const _component_star_icon = _resolveComponent("star-icon")!
  const _component_text_phone = _resolveComponent("text-phone")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_time_formatter = _resolveComponent("time-formatter")!
  const _component_app_simple_checkbox = _resolveComponent("app-simple-checkbox")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_shift_modal = _resolveComponent("shift-modal")!
  const _component_app_row = _resolveComponent("app-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createVNode(_component_title_return, {
              icon: "task-sheet",
              "icon-size": "19",
              "is-return": false
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Задачи ")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("form", _hoisted_2, [
            _createVNode(_component_form_select, {
              modelValue: _ctx.inlineFilterSettings.date.current,
              items: _ctx.inlineFilterSettings.date.list,
              onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectHandler($event, { key: _ctx.inlineFilterSettings.date.key, action: _ctx.inlineFilterSettings.date.action }))),
              class: "cp-admin-title__wrapper cp-width--200 form-select--not-label cp-margin__r--s"
            }, null, 8, ["modelValue", "items"]),
            _createVNode(_component_app_header_main, {
              "filter-settings": _ctx.filterSettings,
              "is-to-add-hidden": true,
              onResetFilters: _ctx.resetFilters,
              onUpdateList: _ctx.updateList
            }, null, 8, ["filter-settings", "onResetFilters", "onUpdateList"]),
            _createVNode(_component_form_simple_switch, {
              "model-value": _ctx.inlineFilterSettings.viewed.list[0].checked,
              horizontal: true,
              label: "Просмотренные",
              class: "cp-admin-title__wrapper cp-margin__b-no cp-desc cp-margin__r--s",
              onUpdate: _ctx.updateCheckbox
            }, null, 8, ["model-value", "onUpdate"])
          ])
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.settings.table).length > 0)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small grow-1",
            "have-status": true,
            "get-status": _ctx.getStatus,
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            "is-hover": true,
            trackBy: _ctx.trackByItemId,
            onChangePage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination as string, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort
          }, {
            "item-fullName": _withCtx(({ item }) => [
              _createElementVNode("span", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _withDirectives(_createVNode(_component_icon_font, {
                    class: "cp-icon-primary__wrapper cp-icon-color-primary",
                    icon: _ctx.iconWhoseType(item.whoseType).icon,
                    size: "15"
                  }, null, 8, ["icon"]), [
                    [_directive_tooltip, _ctx.iconWhoseType(item.whoseType).tooltip]
                  ]),
                  _createVNode(_component_router_link, {
                    class: "app-table-link",
                    to: { name: 'employees_schedule', params: { employeeId: item.employeeId } },
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.lastName +
                (item.firstName ? ` ${item.firstName[0]}.` : '') +
                (item.firstName && item.middleName ? ` ${item.middleName[0]}.` : '')), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _createVNode(_component_planning_badge, {
                  class: "cp-margin__l--3",
                  mark: item.planningMark
                }, null, 8, ["mark"]),
                (item.isTest)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Test"))
                  : _createCommentVNode("", true),
                _createVNode(_component_icon_font, {
                  class: "app-table-icon app-table-icon--active app-table-icon--row-hover cp-icon-hover",
                  icon: "copy",
                  size: "16",
                  onClick: _withModifiers(($event: any) => (_ctx.copy(item)), ["stop"])
                }, null, 8, ["onClick"])
              ])
            ]),
            "header-item-rating": _withCtx(({ header }) => [
              _withDirectives(_createVNode(_component_star_icon, null, null, 512), [
                [_directive_tooltip, header.name]
              ])
            ]),
            "item-rating": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.prepareRating(value)), 1))
                : _createCommentVNode("", true)
            ]),
            "item-market": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_7, [
                _createTextVNode(_toDisplayString(value) + " ", 1),
                (item.isTestMarket)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-client": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_9, [
                _createTextVNode(_toDisplayString(value) + " ", 1),
                (item.isTestClient)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-vacancy": _withCtx(({ value, item }) => [
              _createVNode(_component_router_link, {
                class: "app-table-link",
                to: { name: 'template_edit', params: { customerId: item.clientId, vacancyId: item.vacancyId } },
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            "item-phone": _withCtx(({ value, item }) => [
              value
                ? _withDirectives((_openBlock(), _createBlock(_component_text_phone, {
                    key: 0,
                    value: value,
                    onPhoneClick: ($event: any) => (_ctx.makeCall(item.employeeId))
                  }, null, 8, ["value", "onPhoneClick"])), [
                    [
                      _directive_tooltip,
                      'Телефон исполнителя',
                      void 0,
                      { right: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ]),
            "item-confirmTime": _withCtx(({ value, item }) => [
              value
                ? _withDirectives((_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    class: "cp-text-overflow",
                    value: _ctx.convertUtcToLocalDatetime(value, item.timeZone),
                    "format-to": "DD.MM.YYYY HH:mm"
                  }, null, 8, ["value"])), [
                    [
                      _directive_tooltip,
                      'Время подтверждения заказа',
                      void 0,
                      { right: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ]),
            "item-shiftTime": _withCtx(({ value, item }) => [
              _withDirectives((_openBlock(), _createElementBlock("span", {
                class: "app-table-link cp-text-overflow",
                onClick: ($event: any) => (_ctx.openShift(item))
              }, [
                _createTextVNode(_toDisplayString(value), 1)
              ], 8, _hoisted_11)), [
                [
                  _directive_tooltip,
                  'Время заказа',
                  void 0,
                  { right: true }
                ]
              ])
            ]),
            "item-status": _withCtx(({ value, item }) => [
              (value in _ctx.statuses)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.statuses[value]) + " " + _toDisplayString(_ctx.getPlanningMarkStatus(item)), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            "item-workedMinutes": _withCtx(({ value, item }) => [
              (item.unitTypeName === 'ч')
                ? (_openBlock(), _createBlock(_component_time_formatter, {
                    key: 0,
                    value: value
                  }, null, 8, ["value"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(item.quantity) + " " + _toDisplayString(item.unitTypeName), 1))
            ]),
            "item-confirmState": _withCtx(({ value, item }) => [
              (item.status === 'reserve')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode("-")
                  ], 64))
                : (!item.needConfirm)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode("Не требуется")
                    ], 64))
                  : (value === 'confirmed')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createTextVNode("Подтвердил")
                      ], 64))
                    : (value === 'refused')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                          _createTextVNode("Отказался")
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                          _createTextVNode("Не подтвердил")
                        ], 64))
            ]),
            "item-startedAt": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    class: "cp-text-overflow",
                    value: value,
                    "format-to": "DD.MM.YYYY HH:mm"
                  }, null, 8, ["value"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode("-")
                  ], 64))
            ]),
            "item-viewed": _withCtx(({ value, item }) => [
              _createVNode(_component_app_simple_checkbox, {
                "is-fill": true,
                "model-value": value,
                "true-value": true,
                "false-value": false,
                onUpdate: ($event: any) => (_ctx.updateViewed(item.id, $event))
              }, null, 8, ["model-value", "onUpdate"])
            ]),
            _: 1
          }, 8, ["get-status", "headers", "items", "page", "total-pages", "total-items", "page-items", "trackBy", "onSelectAmount", "onSort"]))
        : _createCommentVNode("", true),
      (_ctx.isShowShiftModal)
        ? (_openBlock(), _createBlock(_component_shift_modal, {
            key: 2,
            "shop-id": _ctx.shopId,
            "service-type-id": _ctx.serviceTypeId,
            "shift-id": _ctx.shiftIdForModal,
            onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShowShiftModal = false))
          }, null, 8, ["shop-id", "service-type-id", "shift-id"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}