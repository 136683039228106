
import { ref } from 'vue';
import moment from 'moment';
import { Component, Vue } from '@/lib/decorator';
import { EventBus } from '@/lib/event-bus';
import { makePhoneCall } from '@/lib/util/phone';
import { PlanningMark } from '@/lib/util/consts';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterModel, FilterSettings } from '@/lib/layouts/page/filter.interface';
import { StatusListInterface } from '@/interfaces/ui/table/status.interface';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { getEmployeePhone } from '@/api/employees';
import { minutesToTime } from '@/lib/Utils';

import TasksEntityModule from '@/store/tasks/entity';
import OutputsModule from '@/store/tasks/outputs/index';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import AppSimpleCheckbox from '@/components/ui/form/SimpleCheckbox.vue';
import AppTable from '@/components/ui/table/Table.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import PlanningBadge from '@/components/table-items/PlanningBadge.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import IconFont from '@/components/icons/IconFont.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import StarIcon from '@/components/icons/Star.vue';
import ShiftModal from '@/views/shops/shift/form/shiftModal.vue';
import TimeFormatter from '@/components/TimeFormatter.vue';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    TimeFormatter,
    AppCol,
    AppRow,
    IconFont,
    TextDatetime,
    AppSimpleCheckbox,
    TextPhone,
    AppTable,
    TabsNav,
    TitleReturn,
    AppHeaderMain,
    FormSelect,
    FormSimpleSwitch,
    PlanningBadge,
    StarIcon,
    ShiftModal,
  },
})
export default class OutputsMain extends Vue {
  statusList: StatusListInterface = {
    warning: '#ffc42b',
  };

  isShowShiftModal = false;
  shopId?: string;
  serviceTypeId?: string;
  shiftIdForModal?: string;

  getTime(minutes: number): string {
    return minutesToTime(minutes);
  }

  getStatus(row: { confirmTime: string }): { color: string } | undefined {
    if (!row.confirmTime) {
      return { color: this.statusList.warning };
    }
  }

  filter = ref(null);

  get tabsNav(): TabsNavInterface {
    return TasksEntityModule.tabsNav;
  }

  get currentTab(): string {
    return TasksEntityModule.tabsNav.outputs?.id;
  }

  get settings(): PageInterface {
    return OutputsModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return OutputsModule.filter.filterSettings;
  }

  get statuses(): Record<string, string> {
    return {
      came_out: 'Вышел',
      did_not_go_out: 'Не вышел',
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      reserve: 'Резерв',
      refused_in_this_market: 'Отказался/место',
      fake_call: 'Ложный вызов',
      fast_replacement: 'Срочная замена',
    };
  }

  get inlineFilterSettings(): FilterModel {
    return OutputsModule.inlineFilter.filterSettings.filterModel;
  }

  prepareRating(value: string): string {
    return value.substring(0, 3);
  }

  trackByItemId(item: { id: string }): string {
    return item.id;
  }

  getPlanningMarkStatus(item: { status: string; planningMark: string }): string | void {
    if (item.status !== 'is_planned') {
      return;
    }

    switch (item.planningMark) {
      case PlanningMark.FromReserve:
        return '(из резерва)';
      case PlanningMark.Auto:
        return '(авто)';
    }
  }

  convertUtcToLocalDatetime(value: string, offset: number): string {
    return moment(value).add(offset, 'hours').format();
  }

  async selectHandler(selected: { id: string; value: string }, params: { key: string; action: string }): Promise<void> {
    OutputsModule.SET_PAGE(1);
    await this.$store.dispatch(params.action, {
      key: params.key,
      value: selected,
    });

    await OutputsModule.inlineFilter.updateFilter();
    await OutputsModule.getList();
  }

  async updateCheckbox(value: boolean): Promise<void> {
    OutputsModule.inlineFilter.updateCheckbox({
      key: 'viewed',
      checkbox: {
        bool: value,
        id: 'viewed',
        name: 'Просмотренные',
      },
    });

    await OutputsModule.inlineFilter.updateFilter();
    await OutputsModule.getList();
  }

  async resetFilters(): Promise<void> {
    await OutputsModule.setPageToBegin();
    await OutputsModule.filter.resetFilter();
    await OutputsModule.getList();
  }

  async updateList(): Promise<void> {
    await OutputsModule.setPageToBegin();
    await OutputsModule.filter.updateFilter();
    await OutputsModule.getList();
  }

  selectAmount(value: string): void {
    OutputsModule.updatePageAmountItems(value);
  }

  updateViewed(id: string, bool: boolean): void {
    OutputsModule.updateViewed({ id, bool });
  }

  openShift(item: { serviceTypeId: number; shiftId: number }): void {
    this.serviceTypeId = item.serviceTypeId?.toString();
    this.shiftIdForModal = item.shiftId.toString();
    this.isShowShiftModal = true;
  }

  sort(header: { id: string; sort: { value: string } }): void {
    OutputsModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  copy(item: { lastName: string; firstName: string; middleName: string }): void {
    navigator.clipboard
      .writeText(`${item.lastName} ${item.firstName} ${item.middleName || ''}`.trim())
      .then(() => {
        ResponseHandlerModule.showNotify({ message: 'ФИО скопирован', type: 'ok' });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  savedEditShift(): void {
    OutputsModule.getList();
  }

  async mounted(): Promise<void> {
    await TasksEntityModule.initTabsItems();
    await OutputsModule.init();
    EventBus.$on('savedShift', this.savedEditShift);
  }

  async makeCall(employeeId: string): Promise<void> {
    try {
      const phone = await getEmployeePhone(employeeId);
      makePhoneCall(phone);
    } catch (error) {
      console.error(error);
    }
  }

  iconWhoseType(value: string): { icon: string; tooltip: string } {
    switch (value) {
      case 'fromPartner': {
        return { icon: 'partners', tooltip: 'От партнёра' };
      }
      case 'fromHome': {
        return { icon: 'home', tooltip: 'Свой' };
      }
      case 'fromAgent': {
        return { icon: 'scout', tooltip: 'От скаута' };
      }
    }

    return { icon: '', tooltip: '' };
  }

  unmounted(): void {
    EventBus.$off('savedShift', this.savedEditShift);
  }
}
