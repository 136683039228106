import { makeFormRequest, makeGetRequest } from '@/api/requests';
import API from '@/config/api-routers';
import { AssemblerUrl } from '@/lib/UtilsApi';

/**
 * Global API for outputs.
 *
 * @param pageCurrent
 * @param sort
 * @param filter
 * @param pageAmountItems
 *
 * @access CAN_MARK_GOING_OUT_VIEWED
 */

export const getOutputsList = async (pageCurrent: number, sort: string, filter: string, pageAmountItems?: string) => {
  const assemblerUrl = new AssemblerUrl(API.outputs.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Update outputs Viewed
 *
 * @param id
 * @param data
 *
 * @access CAN_MARK_GOING_OUT_VIEWED
 */
export const updateViewed = async (id: string, data: {}) => {
  const url = API.outputs.edit.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};
