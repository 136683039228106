import { watch } from 'vue';
import UserModule from '@/store/user';
import FilterFactory from '@/lib/layouts/page/filterFactory';
import { phoneMask } from '@/lib/layouts/page/filterMasks';
import { ShiftEmployeeStatus } from '@/lib/util/consts';

export default class OutputsFilter {
  filterHandlers = {
    update: 'outputsFilter/updateFilter',
    reset: 'outputsFilter/resetFilter',
    resetState: 'outputsFilter/resetFilterState',
    removeSelected: 'outputsFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSearchEntity('fullName');
    filter.setPlaceholder('fullName', 'По ФИО');
    filter.setName('fullName', 'search_fullName');
    filter.setPreviewName('fullName', 'ФИО');
    filter.setIconClassName('fullName', 'icon-user-search-light');
    filter.setClassName('fullName', 'col-1-row-1-full');
    filter.setAction('fullName', 'outputsFilter/updateSearch');

    filter.createSearchEntity('phone');
    filter.setPlaceholder('phone', 'По номеру телефона');
    filter.setName('phone', 'search_phone');
    filter.setPreviewName('phone', 'Тел');
    filter.setClassName('phone', 'col-1-row-2-full phone_mask');
    filter.setIconClassName('phone', 'icon-phone');
    filter.setAction('phone', 'outputsFilter/updateSearch');
    filter.setMask('phone', phoneMask);

    filter.createSearchEntity('market');
    filter.setPlaceholder('market', 'По объекту');
    filter.setName('market', 'search_fullMarketName');
    filter.setPreviewName('market', 'Объект');
    filter.setClassName('market', 'col-1-row-3-full');
    filter.setIconClassName('market', 'icon-store');
    filter.setAction('market', 'outputsFilter/updateSearch');

    filter.createTimestampIntervalPickerEntity('confirmTime');
    filter.setName('confirmTime', 'picker_timestamp_confirm_time');
    filter.setPreviewName('confirmTime', 'Время отв.');
    filter.setTitle('confirmTime', 'Время ответа');
    filter.setDateIntervalList('confirmTime', {
      startTimestamp: {
        key: 'confirmTimeFrom',
        type: 'timestamp-range',
        title: 'Дата/время ответа, с',
        placeholder: 'Ответ, с',
        time: '',
        date: '',
        action: 'outputsFilter/updateStartTimestamp',
        required: true,
        start: 0,
        end: 24,
        step: 30,
      },
      endTimestamp: {
        key: 'confirmTimeTo',
        type: 'timestamp-range',
        title: 'Дата/время ответа, до',
        placeholder: 'Ответ, до',
        time: '',
        date: '',
        action: 'outputsFilter/updateEndTimestamp',
        required: true,
        start: 0,
        end: 24,
        step: 30,
      },
    });
    filter.setClassName('confirmTime', 'col-1-row-4-full');
    filter.setIconClassName('confirmTime', 'icon-timestamp');

    filter.createTimeIntervalPickerEntity('shiftTime');
    filter.setName('shiftTime', 'picker_time_shift_time');
    filter.setPreviewName('shiftTime', 'Время зак.');
    filter.setTitle('shiftTime', 'Время заказа');
    filter.setAction('shiftTime', 'outputsFilter/updateTimesRange');
    filter.setDateIntervalList('shiftTime', {
      from: {
        title: 'Время заказа, с',
        placeholder: 'Заказ, с',
        hour: '',
        minute: '',
        value: '',
      },
      to: {
        title: 'Время заказа, до',
        placeholder: 'Заказ, до',
        hour: '',
        minute: '',
        value: '',
      },
    });
    filter.setClassName('shiftTime', 'col-1-row-5-full');
    filter.setIconClassName('shiftTime', 'icon-clock');

    filter.createCheckboxEntity('isNotTest');
    filter.setTitle('isNotTest', 'Без тестовых');
    filter.setName('isNotTest', 'checkbox_isNotTest');
    filter.setPreviewName('isNotTest', 'Без тестовых');
    filter.setIgnoreNull('isNotTest', true);
    filter.setListElement('isNotTest', {
      id: 'isNotTest',
      name: 'Без тестовых',
      checked: false,
    });
    filter.setAction('isNotTest', 'outputsFilter/updateCheckbox');
    filter.setClassName('isNotTest', 'col-1-row-6-part-1');
    filter.setIconClassName('isNotTest', 'icon-is-active');

    filter.createCheckboxEntity('plannedInHours');
    filter.setTitle('plannedInHours', 'Быстрый заказ');
    filter.setName('plannedInHours', 'checkbox_plannedInHours');
    filter.setPreviewName('plannedInHours', 'Быстрый заказ');
    filter.setClassName('plannedInHours', 'col-1-row-6-part-2');
    filter.setIconClassName('plannedInHours', 'icon-is-active');
    filter.setAction('plannedInHours', 'outputsFilter/updateCheckbox');
    filter.setIgnoreNull('plannedInHours', true);
    filter.setListElement('plannedInHours', {
      id: 'plannedInHours',
      name: 'Быстрый заказ',
      checked: false,
    });

    filter.createMultiSelectEntity('client');
    filter.setTitle('client', 'По клиенту');
    filter.setName('client', 'multi_select_client');
    filter.setPreviewName('client', 'Клиент');
    filter.setClassName('client', 'col-2-row-1-full');
    filter.setIconClassName('client', 'icon-clients');
    filter.setAction('client', 'outputsFilter/updateMultiSelect');
    filter.setInitAction('client', 'outputs/initClientsSelect');
    filter.setClearAction('client', 'outputs/resetClient');
    filter.setSearchEnabled('client', true);

    filter.createMultiSelectEntity('serviceType');
    filter.setTitle('serviceType', 'По типу услуги');
    filter.setName('serviceType', 'multi_select_order_type');
    filter.setPreviewName('serviceType', 'Тип услуги');
    filter.setClassName('serviceType', 'col-2-row-2-full');
    filter.setIconClassName('serviceType', 'icon-briefcase');
    filter.setAction('serviceType', 'outputsFilter/updateMultiSelect');
    filter.setInitAction('serviceType', 'outputs/initServiceTypeSelect');
    filter.setClearAction('serviceType', 'outputsFilter/resetMultiSelect');
    filter.setSearchEnabled('serviceType', true);

    filter.createMultiSelectEntity('partner');
    filter.setTitle('partner', 'По партнёру заказа');
    filter.setName('partner', 'multi_select_partner');
    filter.setPreviewName('partner', 'Партнёр');
    filter.setIconClassName('partner', 'icon-briefcase');
    filter.setAction('partner', 'outputsFilter/updateMultiSelect');
    filter.setInitAction('partner', 'outputs/initPartnersSelect');
    filter.setClearAction('partner', 'outputsFilter/resetMultiSelect');
    filter.setClassName('partner', 'col-2-row-3-full');
    filter.setSearchEnabled('partner', true);

    filter.createTitle('title');
    filter.setTitle('title', 'Подтверждение выхода:');
    filter.setName('title', 'text_title');
    filter.setClassName('title', 'col-2-row-4-full');

    filter.createCheckboxEntity('confirmed');
    filter.setName('confirmed', 'checkbox_confirmed');
    filter.setPreviewName('confirmed', 'Подтв.');
    filter.setClassName('confirmed', 'col-2-row-5-part-1');
    filter.setIconClassName('confirmed', 'icon-is-active');
    filter.setAction('confirmed', 'outputsFilter/updateCheckbox');
    filter.setIgnoreNull('confirmed', true);
    filter.setListElement('confirmed', {
      id: 'confirmed',
      name: 'Подтвердил',
      checked: false,
    });

    filter.createCheckboxEntity('did_not_confirm');
    filter.setName('did_not_confirm', 'checkbox_did_not_confirm');
    filter.setPreviewName('did_not_confirm', 'Не подтв.');
    filter.setClassName('did_not_confirm', 'col-2-row-5-part-2');
    filter.setIconClassName('did_not_confirm', 'icon-is-active');
    filter.setAction('did_not_confirm', 'outputsFilter/updateCheckbox');
    filter.setIgnoreNull('did_not_confirm', true);
    filter.setListElement('did_not_confirm', {
      id: 'did_not_confirm',
      name: 'Не подтвердил',
      checked: false,
    });

    filter.createCheckboxEntity('no_need_confirm');
    filter.setName('no_need_confirm', 'checkbox_no_need_confirm');
    filter.setPreviewName('no_need_confirm', 'Не треб.');
    filter.setClassName('no_need_confirm', 'col-2-row-6-part-1');
    filter.setIconClassName('no_need_confirm', 'icon-is-active');
    filter.setAction('no_need_confirm', 'outputsFilter/updateCheckbox');
    filter.setIgnoreNull('no_need_confirm', true);
    filter.setListElement('no_need_confirm', {
      id: 'no_need_confirm',
      name: 'Не требуется',
      checked: false,
    });

    filter.createCheckboxEntity('refused');
    filter.setName('refused', 'checkbox_refused');
    filter.setPreviewName('refused', 'Отк.');
    filter.setClassName('refused', 'col-2-row-6-part-2');
    filter.setIconClassName('refused', 'icon-is-active');
    filter.setAction('refused', 'outputsFilter/updateCheckbox');
    filter.setIgnoreNull('refused', true);
    filter.setListElement('refused', {
      id: 'refused',
      name: 'Отказался',
      checked: false,
    });

    filter.createCheckboxEntity('status');
    filter.setName('status', 'checkbox_reserve');
    filter.setPreviewName('status', 'В резерве');
    filter.setClassName('status', 'col-2-row-7-part-1');
    filter.setIconClassName('status', 'icon-is-active');
    filter.setAction('status', 'outputsFilter/updateCheckbox');
    filter.setIgnoreNull('status', true);
    filter.setListElement('status', {
      id: 'status',
      name: 'В резерве',
      checked: false,
      value: ShiftEmployeeStatus.Reserve,
    });

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        filter.setIsHidden('partner', !isSupervisor);

        let rowOffset = isSupervisor ? 4 : 3;
        filter.setClassName('title', `col-2-row-${rowOffset++}-full`);
        filter.setClassName('confirmed', `col-2-row-${rowOffset++}-part-1`);
        filter.setClassName('did_not_confirm', `col-2-row-${rowOffset++}-part-2`);
        filter.setClassName('no_need_confirm', `col-2-row-${rowOffset++}-part-1`);
        filter.setClassName('refused', `col-2-row-${rowOffset++}-part-2`);
        filter.setClassName('status', `col-2-row-${rowOffset++}-part-1`);
      },
      { immediate: true }
    );

    this.filterModel = filter.filterModel;
  }
}
