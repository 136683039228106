
import './planningBadge.scss';
import { defineComponent, PropType } from 'vue';
import { PlanningMark } from '@/lib/util/consts';

const PlanningMarkBadgeTitle: Record<PlanningMark, string> = {
  [PlanningMark.FromReserve]: 'Из резерва',
  [PlanningMark.Auto]: 'Авто',
};

const PlanningMarkBadgeTooltip: Record<PlanningMark, string> = {
  [PlanningMark.FromReserve]: 'Запланирован из резерва',
  [PlanningMark.Auto]: 'Запланирован автоматически',
};

const PlanningMarkBadgeClass: Record<PlanningMark, string> = {
  [PlanningMark.FromReserve]: 'planning-badge_reserve',
  [PlanningMark.Auto]: 'planning-badge_auto',
};

export default defineComponent({
  props: {
    mark: {
      type: String as PropType<PlanningMark>,
      default: '',
    },
  },
  computed: {
    title(): string {
      return PlanningMarkBadgeTitle[this.mark];
    },
    tooltip(): string {
      return PlanningMarkBadgeTooltip[this.mark];
    },
    classModifier(): string {
      return PlanningMarkBadgeClass[this.mark];
    },
  },
});
