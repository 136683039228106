import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
/**
 * Returns processed shops info for shops table
 *
 * @param {Object} table employees data
 * @param {Object} state component's state
 * @returns {Object} tableInfo
 */
export function prepareList(table: TableApiInterface, state: any) {
  const uiTable = new UITable(table as any);
  const sorts = Object.values(state.pageSettings.sort) as { sort: string; key: string }[];

  return uiTable
    .removeColumn('isTest')
    .removeColumn('isTestClient')
    .removeColumn('isTestMarket')
    .removeColumn('planningMark')
    .removeColumn('unitTypeName')
    .removeColumn('quantity')

    .setProperty('fullName', 'width', '180px')
    .setProperty('workedMinutes', 'name', 'Выработка')
    .setProperty('market', 'name', 'Объект')
    .setProperty('workingHours', 'name', 'Выраб, ч')
    .setProperty('status', 'name', 'Статус в заказе')
    .setProperty('confirmState', 'name', 'Подтверждение')
    .setProperty('viewed', 'name', 'Просм')
    .setProperty('startedAt', 'name', 'На заказе')

    .changeTitleOrder('rating', 1)
    .changeTitleOrder('status', 7)
    .changeTitleOrder('confirmState', 8)
    .changeTitleOrder('startedAt', 10)
    .changeTitleOrder('viewed', 11)

    .setSortableValues(sorts)
    .getTable() as any;
}
