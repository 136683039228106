import moment from 'moment';
import FilterFactory from '@/lib/layouts/page/filterFactory';
export default class InlineOutputsFilter {
  filterHandlers = {
    update: 'outputsInlineFilter/updateFilter',
    reset: 'outputsInlineFilter/resetFilter',
    resetState: 'outputsInlineFilter/resetFilterState',
    removeSelected: 'outputsInlineFilter/removeSelectedFilter',
  };
  filterModel: {};

  static readonly dateOptions = ['Вчера', 'Сегодня', 'Завтра', 'Послезавтра'].reduce((options, value, index) => {
    const id = (index - 1).toString();
    const date = moment()
      .add(+id, 'day')
      .format('YYYY-MM-DD');

    options.push({ id, value, date });

    return options;
  }, [] as { id: string; value: string; date: string }[]);

  constructor() {
    const filter = new FilterFactory();

    filter.createSelectEntity('date');
    filter.setName('date', 'select_date');
    filter.setList('date', InlineOutputsFilter.dateOptions);
    filter.setAction('date', 'outputsInlineFilter/updateSelect');
    filter.setClearAction('date', '');
    filter.setCurrent('date', InlineOutputsFilter.dateOptions[1]);

    filter.createCheckboxEntity('viewed');
    filter.setTitle('viewed', 'Просмотренные');
    filter.setName('viewed', 'checkbox_viewed');
    filter.setAction('viewed', 'outputs/updateIsProcessedFilter');
    filter.setListElement('viewed', {
      id: 'viewed',
      name: 'Просмотренные',
      checked: false,
    });

    this.filterModel = filter.filterModel;
  }
}
