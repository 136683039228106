import { Action, Module } from 'vuex-module-decorators';
import store from '@/store';
import FilterModule, { PreparedFilter } from '@/store/filter';
import { Checkbox } from '@/interfaces/filter.interface';
import { filterCheckboxInterface } from '@/lib/layouts/page/filterCheckbox.interface';
import { ShiftEmployeeStatus } from '@/lib/util/consts';
import { convertObjectToQueryParams } from '@/lib/Utils';

@Module({ dynamic: true, store, name: 'outputsFilter', namespaced: true })
export default class OutputsFilter extends FilterModule {
  constructor(module: OutputsFilter) {
    super(module);
  }

  prepareCheckbox(filterEntity: filterCheckboxInterface): PreparedFilter {
    const statusCheckbox = (this.filterSettings.filterModel?.status.list as Checkbox[])?.[0];
    const plannedInHoursCheckbox = (this.filterSettings.filterModel?.plannedInHours.list as Checkbox[])?.[0];

    if (filterEntity.key !== 'status' || !plannedInHoursCheckbox?.checked) {
      return super.prepareCheckbox(filterEntity);
    }

    const filter: PreparedFilter = {
      string: '',
      preview: {},
    };

    const statuses = [ShiftEmployeeStatus.IsPlanned];
    if (statusCheckbox.checked) {
      statuses.push(ShiftEmployeeStatus.Reserve);
    }

    filter.string = `&${convertObjectToQueryParams({
      [`filters[${this.self.index}]`]: {
        id: filterEntity.key,
        value: statuses,
      },
    })}`;

    if (statusCheckbox.checked) {
      filter.preview[filterEntity.key] = {
        name: filterEntity.previewName,
        iterable: false,
        iconClass: filterEntity.iconClass || '',
      };
    }

    return filter;
  }

  @Action({ rawError: true })
  async prepareFilterFromModal(): Promise<PreparedFilter> {
    const result = await super.prepareFilterFromModal();
    this.self.disableCheckboxes();

    return result;
  }

  @Action({ rawError: true })
  async updateCheckbox(params: { key: string; checkbox: Checkbox & { bool: boolean } }): Promise<void> {
    super.updateCheckbox(params);

    this.self.disableCheckboxes();
  }

  disableCheckboxes(): void {
    const reserveFilter = 'status';
    const confirmStateFilters = ['confirmed', 'did_not_confirm', 'no_need_confirm', 'refused'];
    const getCheckboxValue = (key: string) => (this.filterSettings.filterModel[key].list as Checkbox[])?.[0].checked;

    const isCheckedReserve = getCheckboxValue(reserveFilter);
    const isCheckedConfirmState = confirmStateFilters.map(getCheckboxValue).some(Boolean);

    [reserveFilter, ...confirmStateFilters].forEach((key: string) => {
      const disabled = key === reserveFilter ? isCheckedConfirmState : isCheckedReserve;
      this.updateDisabled({ key, value: disabled });
    });
  }
}
